import React from "react";
import { Box, Button } from "@mui/material";
import theme from "../../Styles/theme";
import { useAtom } from "jotai";
import { handleRightActivityAtom } from "../../ViewModels/ChatPageViewModel";
import { RightSide } from "../../Components/RightSide";
import { LeftSide } from "../../Components/LeftSide";

export function Chat() {
  const [showLeftPanel, handleRightActivity] = useAtom(handleRightActivityAtom);

  return (
    <Box display="flex" height="100vh">
      {/* 왼쪽 패널 */}
      <Box
        sx={{
          transition: "width 300ms ease-out",
          boxSizing: "border-box",
          backgroundColor: theme.colors.lightBackground,
          width: showLeftPanel ? "60%" : 0,
          overflowY: "auto",
          minHeight: "100vh",
          maxHeight: "100vh",
          height: "100vh",
        }}
      >
        <LeftSide />
      </Box>

      {/* 메인 컨텐츠 */}
      <Box
        sx={{
          transition: "width 300ms ease-out",
          boxSizing: "border-box",
          backgroundColor: "white",
          width: showLeftPanel ? "40%" : "100%",
        }}
      >
        <RightSide />
      </Box>
    </Box>
  );
}
