import React from "react";
import Root from "./Root";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
import { Chat } from "./Pages/Chat";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/:slug" element={<Root />}>
        <Route path="" element={<Chat />} />
      </Route>
    </Routes>
  );
};

const router = createBrowserRouter([
  {
    path: "*",
    element: <AppRouter />,
  },
]);

export default router;
