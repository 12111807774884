import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Provider as JotaiProvider } from "jotai";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import router from "./Router";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'NanumSquareNeoBrg';
    src: url('/NanumSquareNeo-bRg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body, html {
    margin: 0;
    padding: 0;
    font-family: 'NanumSquareNeoBrg', sans-serif;
    line-height: 1.7;
  }

  * {
    font-family: 'NanumSquareNeoBrg', sans-serif !important;
    line-height: 1.7;
  }

  .canvas {
    height: 100vh;
    left: 0px;
    pointer-events: none;
    position: fixed;
    top: 0px;
    width: 100vw;
  }
`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <JotaiProvider>
      <GlobalStyle />
      <RouterProvider router={router} />
    </JotaiProvider>
  </QueryClientProvider>
);
