export async function virtualTryOn(
  category: string,
  cloth_url: string,
  model_url: string,
  cloth_name: string
): Promise<{ data: any; type: string } | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
    },
    body: JSON.stringify({
      category: category,
      cloth_url: cloth_url,
      model_url: model_url,
      cloth_name: cloth_name,
    }),
  };

  const response = await fetch(
    `https://dashboard.dokgabi.ai/api/virtualTryOn`,
    requestOptions
  );
  if (!response.ok) {
    console.log(response.json());
    return null;
  }
  const result = await response.json();

  return {
    data: result.results,
    type: "virtual",
  };
}
