import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import * as S from "./Components/Root/styled";
import { useAtom } from "jotai";
import { currentChatbotAtom } from "./Atoms/PublicAtom";

function Root() {
  const { slug } = useParams<{ slug: string }>(); // URL에서 슬러그 값 추출
  const [currentChatbot, setCurrentChatbot] = useAtom(currentChatbotAtom);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!slug) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getSocketFLow`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json", // JSON 형식으로 지정
              Authorization: `Bearer ${process.env.REACT_APP_ACCESS_KEY}`,
            },
            body: JSON.stringify({
              id: slug,
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const result = await response.json();
        setCurrentChatbot(result.results);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) {
    return <S.FullContainer>Loading...</S.FullContainer>;
  }

  if (error) {
    return <S.FullContainer>Error: {error}</S.FullContainer>;
  }

  if (currentChatbot === null) return null;

  return (
    <S.FullContainer>
      <Outlet />
    </S.FullContainer>
  );
}

export default Root;
