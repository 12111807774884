import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
} from "@mui/material";
import { useAtom } from "jotai";
import { dataListAtom, mapDataAtom } from "../../Atoms/PublicAtom";
import { Map } from "./Map";
import BarChart from "./Bar";
import LineChart from "./Line";
import ScatterPlot from "./Scatter";

export function LeftSide() {
  const [dataList] = useAtom(dataListAtom);
  const [mapData] = useAtom(mapDataAtom);

  return (
    <>
      {mapData && (
        <Box sx={{ height: "100vh", width: "100%" }}>
          <Map data={mapData.data} />
        </Box>
      )}
      <Grid container spacing={2} sx={{ overflowY: "auto" }} padding={5}>
        {dataList.map((data, index) => (
          <Grid
            item
            xs={12} // 모바일: 1개씩
            sm={6} // 태블릿: 2개씩
            md={6} // 데스크톱: 3개씩
            key={`${index}-data-list`}
          >
            <Card
              key={`${index}-data-list-card`}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {data.type === "virtual" ? (
                <CardMedia
                  component="img"
                  sx={{
                    objectFit: "cover",
                    border: "none",
                  }}
                  image={data.data.output as unknown as string}
                  alt="chatbot icon"
                />
              ) : data.data.chart_type === "bar" ? (
                <BarChart
                  chartTitle={data.data.chart_title}
                  xLabel={data.data.x_label}
                  yLabel={data.data.y_label}
                  data={data.data.data}
                />
              ) : data.data.chart_type === "line" ? (
                <LineChart
                  chartTitle={data.data.chart_title}
                  xLabel={data.data.x_label}
                  yLabel={data.data.y_label}
                  data={data.data.data}
                />
              ) : (
                <ScatterPlot
                  chartTitle={data.data.chart_title}
                  xLabel={data.data.x_label}
                  yLabel={data.data.y_label}
                  data={data.data.data}
                />
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
