import { atom } from "jotai";
import { ChattingProps, DokgabiFlowProps } from "../Props/PublicProps";

export const leftSideOpenAtom = atom(false);

// 부품 디자이너 "qLaTc87Ggp6vg8igysGMMxf8lc1zRyaGZRAN"
// 제주도 "wU73NFLoQnbuOnKAuwUjd476mhJ8t0u6V1F3"
// 조향사 "UijMiBqXQtfT7lNKl8As97GPXWW1LE9aQ7hx"
// 패션 디자이너 "5giaSdzrkwV2Hdf69lXixwgLPuKeU1r8yXmP"
export const currentChatbotAtom = atom<DokgabiFlowProps | null>(null);

export const chattingListAtom = atom<ChattingProps[]>([]);

export const scrollStateAtom = atom(false);

export enum ChatType {
  ASK = 0,
  SEARCH = 1,
}

export const chattingTypeAtom = atom<ChatType>(ChatType.ASK);
export const isChattingAskAtom = atom<HTMLElement | null>(null);
export const isOpenRecommendQuestionAtom = atom(false);
export const isAIMakingResponseAtom = atom(false);

export const chatInputValueAtom = atom("");
export const searchInputValueAtom = atom("");
export const currentSearchIndexAtom = atom(0);

export const recommendCurrentPageAtom = atom(0);

export const chatHistoryPageNumAtom = atom(1);
export const isPageGettingHistoryAtom = atom(false);

export const userIdAtom = atom("testUser123");

export const currentCharacterAtom = atom("");

export const dataListAtom = atom<
  {
    type: string;
    data: any;
  }[]
>([]);

export const mapDataAtom = atom<any | null>(null);
