import { useQuery } from "@tanstack/react-query";
import {
  DokgabiFlowProps,
  DokgabiFrontElementProps,
} from "../Models/DokgabiFlow";
import { ChattingProps } from "../Props/PublicProps";
import { ResponseStatus } from "../Enum/NetworkEnum";

async function getMyFlow(id: string): Promise<DokgabiFlowProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${process.env.REACT_APP_ACCESS_KEY}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getMyFlow`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();

  return result.results;
}

export function useGetFlowDataQuery(myId: string) {
  return useQuery({
    queryKey: [`${myId}-flow`],
    queryFn: () => getMyFlow(myId),
  });
}

export async function requestChatbot(
  access: string,
  input_message: string,
  user_id: string,
  flow_socket_name: string,
  character: string,
  handleStreamMessage: (
    message: string,
    isFirst: boolean,
    isNotStream: boolean,
    flow_socket_name: string
  ) => void,
  handleJson: (data: any) => void
): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      flow: flow_socket_name,
      message: input_message,
      user_id: user_id,
      character_name: character,
    }),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/makeChatbotResponseWithCharacter`,
      requestOptions
    );

    if (response.status === 401) {
      return ResponseStatus.UNAUTHORIZED;
    } else if (response.status === 403) {
      return ResponseStatus.FORBIDDEN;
    } else if (!response.ok) {
      return ResponseStatus.ERROR;
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let isFirst = true;
    let message = "";

    while (true) {
      const { done, value } = await reader!.read();

      if (done) {
        // 여기에 음성을 출력하는 기능 추가
        break;
      }

      const chunk = decoder.decode(value, { stream: true });

      if (chunk.startsWith("JSON:")) {
        // JSON 문자열 파싱 시도
        const json = JSON.parse(chunk.slice(5));
        console.log(json);
        handleJson(json);
      } else {
        message += chunk;
        handleStreamMessage(message, isFirst, false, flow_socket_name);

        isFirst = false;
      }
    }
  } catch (error) {
    return ResponseStatus.ERROR;
  }
}

async function getMyChatbotFrontElement(
  id: number
): Promise<DokgabiFrontElementProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${process.env.REACT_APP_ACCESS_KEY}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getMyChatbotFrontElement`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();

  return result.results;
}

export function useGetMyChatbotFrontElement(myId: number) {
  return useQuery({
    queryKey: [`${myId}-front-element`],
    queryFn: () => getMyChatbotFrontElement(myId),
  });
}

export async function getChatbotHistory(
  flow_id: number,
  user_id: string,
  numpage: number
): Promise<ChattingProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${process.env.REACT_APP_ACCESS_KEY}`,
    },
    body: JSON.stringify({
      flow_id: flow_id,
      user_id: user_id,
      numpage: numpage,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getChatHistory`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();

  return result.results;
}
